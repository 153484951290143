export const log = (data) => {
  console.log(data)
}

export const decode = (str) => {
  let txt = new DOMParser().parseFromString(str, 'text/html')
  return txt.documentElement.textContent
}

export const removeSpecialChars = (string) =>
  string.replace(/[^a-zA-Z0-9\s]/g, '')
