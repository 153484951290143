import { EmptyPost, Frame, SeoScore, UIBlocker } from '#components'
import { useMeta, useScan, useTokenStatus } from '#hooks'
import {
  EnoughWords,
  FocusKeyword,
  ImagesAltText,
  KeywordInASubheader,
  MetaTags,
  PerfectTitle,
} from '#tasks'
import { Button, Flex, Spinner, TabPanel } from '@wordpress/components'
import { useContext, useEffect, useState } from '@wordpress/element'
import { Trans as T, useTranslation } from 'react-i18next'
import { EditorContext } from './services/EditorContext'
import { getPop } from './services/getPop'

import './tasks-styles.css'

const getPublishButton = () => {
  const gutenbergPublishButton = document.querySelector(
    '.editor-post-publish-button',
  )

  const elementorPublishButton = document.querySelector(
    '#elementor-panel-saver-button-publish',
  )

  const classicAndBoldgridEditorPublishButton =
    document.querySelector('#publish')

  return (
    gutenbergPublishButton ||
    elementorPublishButton ||
    classicAndBoldgridEditorPublishButton
  )
}

export const Tasks = () => {
  const { t } = useTranslation()
  const [pop, setPop] = useState({ id: null, type: '', status: 'draft' })
  const { data: tokenStatus } = useTokenStatus()
  const { mutate, isValidating: isValidatingScan } = useScan(pop)
  const { data: keywordSelected, isValidating: isValidatingKeyword } = useMeta({
    popId: pop.id,
    key: 'keyword',
  })

  const [isWaitingPublishAndCheck, setIsWaitingPublishAndCheck] =
    useState(false)

  useEffect(() => {
    setIsWaitingPublishAndCheck(isValidatingScan)
  }, [isValidatingScan])

  const editor = useContext(EditorContext)

  const publishAndCheck = () => {
    const publishButton = getPublishButton()
    publishButton.click()
    setIsWaitingPublishAndCheck(true)
  }

  useEffect(() => {
    const publishButton = getPublishButton()

    const observer = new MutationObserver((mutations) => {
      mutations.forEach(({ attributeName }) => {
        const elementorReady =
          editor === 'elementor' &&
          attributeName === 'class' &&
          publishButton
            .getAttribute(attributeName)
            .includes('elementor-disabled')

        const gutenbergReady =
          editor === 'gutenberg' &&
          attributeName === 'aria-disabled' &&
          publishButton.getAttribute(attributeName) === 'false'

        if (elementorReady || gutenbergReady) {
          mutate()
        }
      })
    })

    if (publishButton) {
      observer.observe(publishButton, { attributes: true })

      return () => {
        observer.disconnect()
      }
    }
  }, [])

  useEffect(() => {
    getPop(setPop)
  }, [window.location.search])

  // TODO: avoid call at useTasks thanks to status at Elementor
  if (pop.status === 'draft') return <EmptyPost />

  if (tokenStatus?.success === false)
    return <UIBlocker tokenStatus={tokenStatus?.message} />

  if (isValidatingKeyword)
    return (
      <Flex justify="center" style={{ height: '50vh' }}>
        <Spinner
          style={{
            height: '60px',
            width: '60px',
            position: 'initial',
          }}
        />
      </Flex>
    )

  return (
    <div className="mktseo">
      {!keywordSelected ? (
        <FocusKeyword.Unselected pop={pop} />
      ) : (
        <>
          <SeoScore pop={pop} isBusy={isWaitingPublishAndCheck} />
          <TabPanel
            tabs={[
              {
                name: 'tasks',
                title: t('sidebar.task.title'),
                content: (
                  <>
                    <FocusKeyword.Selected pop={pop} />
                    <PerfectTitle pop={pop} />
                    <KeywordInASubheader pop={pop} />
                    <MetaTags
                      pop={pop}
                      isWaitingPublishAndCheck={isWaitingPublishAndCheck}
                    />
                    <ImagesAltText pop={pop} />
                    <EnoughWords pop={pop} />
                  </>
                ),
              },
            ]}
          >
            {(tab) => <>{tab.content}</>}
          </TabPanel>

          <Frame>
            <b>
              <p>
                <T i18nKey="sidebar.task.check.title" />
              </p>
            </b>
            <p>
              <T i18nKey="sidebar.task.check.content" />
            </p>
            <Button
              isBusy={isWaitingPublishAndCheck}
              disabled={isWaitingPublishAndCheck}
              variant="primary"
              onClick={publishAndCheck}
            >
              <T i18nKey="sidebar.task.check.button" />
            </Button>
          </Frame>
        </>
      )}
    </div>
  )
}
